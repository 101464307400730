import React from 'react';
import Section from './../Section';
import Features from './../Features';
import incorporate_img from './imgs/incorporate.png';
import paperwork_img from './imgs/paperwork.png';
import legal_img from './imgs/legal.png';
import './styles.scss';

function FeaturesSection(props) {
	return (
		<Section color={props.color} size={props.size}>
			<div className='container'>
				<Features
					items={[
						{
							title: 'Incorporate your business',
							description:
								'Create your company online. Get instant access to a bank account and invoicing platform. Start doing business right away. ',
							image: incorporate_img,
						},
						{
							title: 'Automate your paperwork',
							description: 'Automate paperwork filling, invoicing, payroll and expenses.',
							image: paperwork_img,
						},
						{
							title: 'Get expert legal advice',
							description:
								'Get advice from expert attorneys in any business area that matters to your company. ',
							image: legal_img,
						},
					]}
				/>
			</div>
		</Section>
	);
}

export default FeaturesSection;
