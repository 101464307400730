import React from 'react';
import Section from './../Section';
import SectionHeader from './../SectionHeader';
import CTA from './../CTA';
import './styles.scss';

function HeroSection(props) {
	return (
		<Section color={props.color} size={props.size}>
			<div className='container'>
				<div className='columns is-vcentered is-desktop'>
					<div className='column is-6-desktop'>
						<SectionHeader title={props.title} subtitle={props.subtitle} size={1} />
						<CTA
							parentColor='cta'
							buttonText={props.buttonText}
							inputPlaceholder={props.inputPlaceholder}
							subscribedMessage={props.subscribedMessage}
							size='medium'
						/>
					</div>
					<div className='column is-1' />
					<div className='HeroSection__image-container column is-5-desktop'>
						<figure className='HeroSection__image image'>
							<img src={props.image} alt='Illustration' />
						</figure>
					</div>
				</div>
			</div>
		</Section>
	);
}

export default HeroSection;
