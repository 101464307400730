import React from 'react';
import ReactDom from 'react-dom';
import './styles.scss';
import App from './pages/_app';
import * as serviceWorker from './serviceWorker';
// import * as firebase from 'firebase';
// import * as firebase from 'firebase/app';

// if (!firebase.apps.length) {
//   var firebaseConfig = {
//     apiKey: process.env.REACT_APP_API_KEY,
//     authDomain: process.env.REACT_APP_AUTH_DOMAIN,
//     databaseURL: process.env.REACT_APP_DATABASE_URL,
//     projectId: process.env.REACT_APP_PROJECT_ID,
//     storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
//     messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
//     appId: process.env.REACT_APP_ID,
//   };
//   firebase.initializeApp(firebaseConfig);
// }

// Initialize Firebase

ReactDom.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
