import React from 'react';
import Navbar from './../../components/Navbar';
import HomePage from './../home';
import { Switch, Route, Router } from './../../util/router.js';
import Divider from './../../components/Divider';
import CTASection from './../../components/CTASection';
import Footer from './../../components/Footer';
import { ProvideAuth } from './../../util/auth.js';
import './styles.scss';

function App(props) {
	return (
		// <ProvideAuth>
		<Router>
			<>
				<Navbar color='' spaced={true} />

				<Switch>
					<Route exact path='/' component={HomePage} />

					<Route
						component={({ location }) => {
							return (
								<div
									style={{
										padding: '50px',
										width: '100%',
										textAlign: 'center',
									}}>
									The page <code>{location.pathname}</code> could not be found.
								</div>
							);
						}}
					/>
				</Switch>

				<Divider color='light' />
				<CTASection
					color='primary'
					size='medium'
					title='READY TO GET STARTED?'
					subtitle='Request access or contact us'
					buttonText='REQUEST ACCESS'
					inputPlaceholder='ENTER EMAIL'
					subscribedMessage='Thank you for your request. We will get in touch as soon as possbile!'
					footerContainerClass="CTA__container__footer"
					inputClass='input__footer'
				/>
				<Footer
					color='primary'
					size='medium'
					logo='https://uploads.divjoy.com/logo.svg'
					description='Enabling entrepreneurs everywhere'
					copywrite=''
					buttonOnClick={() => {}}
				/>
			</>
		</Router>
		// </ProvideAuth>
	);
}

export default App;
