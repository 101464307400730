import React from 'react';
import './styles.scss';

function Features(props) {
	return (
		<div className='Features' key='features'>
			{props.items.map((item, index) => (
				<div key={index} className='Features__columns columns is-variable is-12 has-text-centered-mobile'>
					<div className='column is-6'>
						<div className='Features_textbox'>
							<h3 className='Features__title title has-text-weight-bold is-spaced is-3'>{item.title}</h3>
							<p className='subtitle'>{item.description}</p>
						</div>
					</div>
					<div className='column is-6'>
						<div className={`bg-block ${index % 2 > 0 ? 'even' : 'odd'}`} />
						<figure className='Features__image image is-6'>
							<img src={item.image} alt={item.title} />
						</figure>
					</div>
				</div>
			))}
		</div>
	);
}

export default Features;
