import React from "react";
import NavbarContainer from "./../NavbarContainer";
import { Link } from "./../../util/router.js";
// import { useState } from "react";
// import { useAuth } from "./../../util/auth.js";
import "./styles.scss";
import logo from './logo_primary.svg';


function Navbar(props) {
  // const auth = useAuth();
  // const [menuOpen, setMenuOpen] = useState(false);

  return (
    <NavbarContainer spaced={props.spaced} color={props.color}>
      <div className="container">
        <div className="navbar-brand">
          <div className="navbar-item">
            <Link to="/">
              <img className="image" src={logo} alt="Logo" />
            </Link>
          </div>
        </div>
      </div>
    </NavbarContainer>
  );
}

export default Navbar;
