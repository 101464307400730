import React from "react";
import HeroSection from "./../../components/HeroSection";
import FeaturesSection from "./../../components/FeaturesSection";
// import { useRouter } from "./../../util/router.js";
import dashboard_img from "./dashboard_img@2x.svg";
import "./styles.scss";

function HomePage(props) {
  // const router = useRouter();

  return (
    <>
      <HeroSection
        color="white"
        size="medium"
        title="The Simplest Way To Manage Your Company"
        subtitle="Register and manage your company online. Handle your paperwork from anywhere."
        buttonText="REQUEST ACCESS"
        inputPlaceholder="ENTER EMAIL"
        image={dashboard_img}
        buttonOnClick={() => {
        }}
        subscribedMessage='Thank you for your request. We will get in touch as soon as possbile!'
      />
      <FeaturesSection
        color="white"
        size="medium"
        title="Features"
        subtitle="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud."
      />
    </>
  );
}

export default HomePage;
