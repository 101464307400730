import React, { useState } from 'react';
import SectionButton from '../SectionButton';
import './styles.scss';

function CTA(props) {
	const [email, setEmail] = useState('');
	const [subscribed, setSubscribed] = useState(false);

	const handleSubmit = () => {
		if (email) {
			setSubscribed(true);
			// Parent component can optionally
			// find out when subscribed.
			props.onSubscribed && props.onSubscribed();

			// Add an API call here. For example:
			//API.CTASubscribe({ email });
		}
	};

	return (
		<>
			{subscribed === false && (
				<form
					onSubmit={e => {
						e.preventDefault();
						handleSubmit();
					}}>
					<div className='field is-grouped is-grouped-multiline'>
						<div className='control is-expanded'>
							<input
								className={`input is-${props.size} ${props.inputClass}`}
								type='email'
								placeholder={props.inputPlaceholder}
								onChange={event => setEmail(event.target.value)}
							/>
						</div>
						<div className='control is-expanded'>
							<SectionButton
								parentColor={props.parentColor}
								size={props.size}
								onClick={props.buttonOnClick}
								fullWidth={true}>
								{props.buttonText}
							</SectionButton>
						</div>
					</div>
				</form>
			)}

			{subscribed === true && <>{props.subscribedMessage}</>}
		</>
	);
}

export default CTA;
